<template>
  <div class="w-full" @mouseenter="open" @mouseleave="enableActionsLeaveMouse? close():null">
    <slot
      name="trigger"
      :set-index="setIndex"
      :index="index"
      :close="close"
    ></slot>
    <div class="relative w-full">
      <transition
        enter-active-class="duration-300 ease-out"
        enter-class="scale-75 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-class="scale-y-100 opacity-100"
        leave-to-class="scale-y-0 opacity-0"
      >
        <div
          v-if="isOpen"
          class="transition-all origin-top transform overflow-ellipsis"
          :class="classContent"
        >
          <slot name="content" :index="index" :close="reset"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    enableActionsLeaveMouse?: boolean,
    classContent?:string
  }>(),
  {
    enableActionsLeaveMouse: true,
    classContent: 'absolute inset-0 z-30'
  }
)
const isOpen = ref(false)
const index = ref<number | null>(null)
const timer = ref<any | null>(null)

function setIndex(indexLcoal: number | null) {
  index.value = indexLcoal
}
defineExpose({
  setIndex,
  open
})

function open(withOutTimeout?:boolean) {
  if (!withOutTimeout) {
    timer.value = setTimeout(() => {
      isOpen.value = true
    }, 500)
  } else {
    isOpen.value = true
  }
}

function reset() {
  close()

  index.value = null
}

function close() {
  clearTimeout(timer.value)

  timer.value = null
  isOpen.value = false
}
</script>
